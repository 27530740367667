import React from "react";
import Sidenavlayout from "../../../components/layout/sidenavLayout/Sidenavlayout";
import AddFinalQuizList from "./AddFinalQuizList";

const AddFinalQuizWrapper = () => {
  return (
    <Sidenavlayout>
      <AddFinalQuizList />
    </Sidenavlayout>
  );
};

export default AddFinalQuizWrapper;
