import logo from "./logo.svg";
import "./App.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import Routing from "./Routing";
import { Toaster } from "react-hot-toast";
import {
  setAccessToken,
  setIsTableLoading,
  setLoading,
  setUser,
} from "./redux/slices/AuthSlice";
import { useEffect } from "react";
import { isValidAdmin } from "./Helper/AuthHelper";

function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const { isTableLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    const checkForValidUser = async () => {
      const userDataString = localStorage.getItem("userData");
      const userData = JSON.parse(userDataString);

      if (userData) {
        const result = await isValidAdmin(userData.accessToken);
        if (!result) {
          localStorage.clear();
        } else {
          dispatch(setLoading(true));
          dispatch(setUser(userData));
          dispatch(setAccessToken(userData.accessToken));
          dispatch(setLoading(false));
        }
      }
      dispatch(setIsTableLoading(true));
    };
    checkForValidUser();
  }, [dispatch, loading]);

  if (!isTableLoading) {
    return null;
  }

  return (
    <>
      <Routing />
      <Toaster />
    </>
  );
}

export default App;
