import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetDashboardQuery } from "../../redux/slices/services/apiSlice";

const colorMapping = {
  Blue: "#37474F",
  Green: "linear-gradient(45deg, #000, #cdcaa9)",
  // Green: "linear-gradient(45deg, #000, #cdcaa9)",
  // Green: "linear-gradient(45deg, #222222, #898989)",
  Teal: "linear-gradient(45deg, #48c9b0, #1abc9c)",
  Purple: "linear-gradient(45deg, #9b59b6, #8e44ad)",
  Gold: "#78909C",
  Yellow: "#FECC00",
  White: "#ffffff",
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading } = useGetDashboardQuery();
  console.log(data);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const DashboardData = [
    {
      title: "Total Courses",
      value: data?.courseCount,
      color: "White",
    },
    {
      title: "Last 5 Registered Users",
      value: 0,
      color: "White",
    },
    {
      title: "Total Corporate Users",
      value: 0,
      color: "White",
    },
    {
      title: "Total Individual Users",
      value: data?.userCount,
      color: "White",
    },
  ];

  return (
    <div className=" pt-5 flex flex-col px-4 h-full w-full  ">
      {/* <BreadCrumbs breadcrumbs={breadcrumbs} /> */}

      <div className="pt-10">
        <h1 className="border-l-4 border-orange-400 px-3 py-2 text-slate-700 font-medium text-2xl">
          Dashboard
        </h1>
      </div>

      <div className="grid lg:grid-cols-12 xs:grid-cols-6 gap-4 pt-10">
        {isLoading ? (
          <div className="col-span-12 text-center text-gray-500">Loading</div>
        ) : (
          // Render data once it's loaded
          DashboardData.map((data, i) => (
            <div
              className="lg:col-span-4 xs:col-span-3 p-4 rounded-md h-40 flex-col items-center justify-between text-center transition-transform transform hover:-translate-y-1"
              style={{
                background: colorMapping[data.color],
                transition:
                  "background 0.3s ease-in-out, transform 0.3s ease-in-out",
              }}
              key={i}
            >
              <div className="text-black font-bold text-[55px]">
                {data.value}
              </div>
              <div className="text-black font-bold py-2 text-lg">
                {data.title}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Dashboard;
