import React, { useEffect, useState } from "react";
import Sidenavlayout from "../../../components/layout/sidenavLayout/Sidenavlayout";
import { FaEye } from "react-icons/fa6";

import { useDispatch } from "react-redux";
import { useGetAllReceiptsQuery } from "../../../redux/slices/services/orderService";
import {
  setIsTableLoading,
  setOrderData,
} from "../../../redux/slices/orderSlice";
import Pagination from "../../../components/Pagination/Pagination";
import Receipts from "./ReceiptsList";
import { useNavigate } from "react-router-dom";

const ReceiptsListWrapper = () => {
  localStorage.setItem("pageNumber", 1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(() => {
    const storedPage = localStorage.getItem("pageNumber");
    return storedPage ? parseInt(storedPage) : 1;
  });

  const { data, isFetching, isLoading } = useGetAllReceiptsQuery(page);
  const totalPages = data?.totalPages;
  const [selectedRows, setSelectedRows] = useState([]);

  const receiptsDetails = [];

  data?.transactions?.forEach((tranx) => {
    const details = {
      _id: tranx?._id,
      userName: tranx?.orderId?.name,
      mobileNo: tranx?.orderId?.mobileNo,
      companyName: tranx?.orderId?.companyName,
      orderDate: tranx?.orderId?.createdAt,
      paymentStatus: tranx?.orderId?.paymentStatus,
      amount: tranx?.amount,
      sessionId: tranx?.sessionId,
    };

    receiptsDetails.push(details);
  });
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setOrderData(data?.data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  const columns = [
    {
      field: "transacionId",
      headerName: "Transaction Id",
      flex: "flex-[2_2_0%]",
      renderCell: (row) => <span> {row.sessionId.slice(0, 25)} </span>,
    },
    {
      field: "Users",
      headerName: "Users",
      flex: "flex-[1.2_1_0%]",
      renderCell: (row) => {
        return <span> {row.userName ? row.userName : "Unkown"} </span>;
      },
    },
    {
      field: "Amount",
      headerName: "Amount",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {row.amount} </span>;
      },
    },
    {
      field: "Company Name",
      headerName: "Company Name",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return (
          <span> {row.companyName ? row.companyName : "Not Specified"} </span>
        );
      },
    },
    {
      field: "Order Date",
      headerName: "Order Date",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return (
          <span>
            {" "}
            {row.orderDate
              ? new Date(row.orderDate).toLocaleDateString()
              : "Unkown"}{" "}
          </span>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return (
          <span> {row.paymentStatus ? row.paymentStatus : "Unknown"} </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <FaEye
          style={{ cursor: "pointer", marginRight: "8px" }}
          onClick={() => handleReceiptView(row)}
        />
      ),
      align: "end",
    },
  ];
  const handleReceiptView = async (row) => {
    try {
      navigate(`/receipts/${row._id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Sidenavlayout>
      <Receipts
        rows={receiptsDetails}
        columns={columns}
        isLoading={isLoading}
        currentPage={page}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
      />
    </Sidenavlayout>
  );
};

export default ReceiptsListWrapper;
