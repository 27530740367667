// AddQuizWrapper.js

import React from "react";
import Sidenavlayout from "../../../components/layout/sidenavLayout/Sidenavlayout";
import EditFinalQuiz from "./EditFinalQuiz";

const EditFinalQuizWrapper = () => {
  return (
    <Sidenavlayout>
      <EditFinalQuiz />
    </Sidenavlayout>
  );
};

export default EditFinalQuizWrapper;
