import React from "react";
import Profile from "./Profile";
import Sidenavlayout from "../../components/layout/sidenavLayout/Sidenavlayout";

const ProfileWrapper = () => {
  return (
    <>
      <Sidenavlayout>
        <Profile />
      </Sidenavlayout>
    </>
  );
};

export default ProfileWrapper;
