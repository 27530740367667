import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCourseByIdQuery,
  useGetCourseQuery,
} from "../../../redux/slices/services/courseServices";
import {
  setCategoryData,
  setIsTableLoading,
} from "../../../redux/slices/CategorySlice";
import { useGetCategoriesAllQuery } from "../../../redux/slices/services/categoryService";
import { useParams } from "react-router-dom";
import {
  setSelectedCourseData,
  setIsTableLoading as coursesetIstableLoading,
} from "../../../redux/slices/CourseSlice";
import { MdDelete } from "react-icons/md";
import ShowDialogBox from "../../../components/DialogBox/ShowDialogBox";
import ReactQuill from "react-quill";
import { IoArrowDownCircleSharp, IoArrowUpCircle } from "react-icons/io5";

const EditCourseListing = ({
  onSubmit,
  onModulesChange,
  apiStatus,
  richTextDescription,
  setRichTextDescription,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const slug = params.slug;
  // const [apiStatus, setApiStatus] = useState(false);
  const { data, isFetching, isLoading } = useGetCategoriesAllQuery();
  const { selectedCourseData } = useSelector((state) => state.course);
  const [selectedVideoNames, setSelectedVideoNames] = useState([]);
  const [videoPath, setVideoPath] = useState(
    selectedCourseData?.modules?.map((module) =>
      module?.parts?.map((part) => part.video || "")
    ) || []
  );
  const [modules, setModules] = useState(selectedCourseData?.modules || []);

  const [formData, setFormData] = useState({
    categoryId: "",
    title: "",
    subTitle: "",
    description: selectedCourseData?.description,
    rating: "",
    price: selectedCourseData?.price,
    overview: selectedCourseData?.overview,
    enabled: selectedCourseData?.enabled,
    courseValidityDays: selectedCourseData?.courseValidityDays,
    coverImage: selectedCourseData.coverImage,
    coverVideo: selectedCourseData.coverVideo,
    courseDuration: selectedCourseData.courseDuration,
  });

  useEffect(() => {
    if (selectedCourseData) {
      setFormData({
        categoryId: selectedCourseData?.categoryId || "",
        title: selectedCourseData?.title || "",
        subTitle: selectedCourseData?.subTitle || "",
        description: selectedCourseData?.description || "",
        rating: selectedCourseData?.rating,
        price: selectedCourseData?.price,
        overview: selectedCourseData?.overview,
        enabled: selectedCourseData?.enabled,
        courseValidityDays: selectedCourseData?.courseValidityDays,
        coverImage: selectedCourseData.coverImage,
        coverVideo: selectedCourseData.coverVideo,
        courseDuration: selectedCourseData.courseDuration,
      });
      setRichTextDescription(selectedCourseData?.description);

      // Initialize modules state from selectedCourseData or fallback to default value
      setModules(selectedCourseData?.modules || []);
      setVideoPath(
        selectedCourseData?.modules?.map((module) =>
          module?.parts?.map((part) => part.video || "")
        ) || []
      );
    }
  }, [selectedCourseData]);

  const {
    data: courseData,
    isLoading: courseIsLoading,
    isFetching: courseisFetching,
  } = useGetCourseByIdQuery(slug);

  useEffect(() => {
    if (!courseIsLoading && !courseisFetching) {
      dispatch(setSelectedCourseData(courseData?.data));
    } else {
      dispatch(coursesetIstableLoading(true));
    }
  }, [dispatch, data, courseisFetching, courseIsLoading]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setCategoryData(data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  useEffect(() => {
    onModulesChange(modules);
  }, [modules, onModulesChange]);

  const handleModuleChange = (index, field, value) => {
    const newModules = [...modules];
    newModules[index] = { ...newModules[index], [field]: value };
    setModules(newModules);
  };

  const handlePartChange = (moduleIndex, partIndex, field, value) => {
    // Make a deep copy of modules
    const newModules = JSON.parse(JSON.stringify(modules));

    // Update the specified part
    newModules[moduleIndex].parts[partIndex] = {
      ...newModules[moduleIndex].parts[partIndex],
      [field]: value,
    };

    // Update the state with the newModules
    setModules(newModules);
  };

  const handleAddPart = (moduleIndex) => {
    const newModules = [...modules];
    newModules[moduleIndex] = {
      ...newModules[moduleIndex],
      parts: [...newModules[moduleIndex].parts, { partNumber: 1, video: "" }],
    };
    setModules(newModules);
  };

  const handleAddModule = () => {
    setModules([
      ...modules,
      {
        name: "",
        summary: "",
        parts: [{ partNumber: 1, video: "" }],
      },
    ]);
  };
  const handleVideoChange = (moduleIndex, partIndex, event) => {
    const newModules = modules.map((module, mIndex) => {
      if (mIndex === moduleIndex) {
        return {
          ...module,
          parts: module.parts.map((part, pIndex) => {
            if (pIndex === partIndex) {
              const file = event.target.files[0];

              // If the user chooses a new video
              if (file) {
                setVideoPath((prevVideoPath) => {
                  // If prevVideoPath is not an array or is undefined, initialize it as an empty array
                  if (
                    !Array.isArray(prevVideoPath) ||
                    prevVideoPath === undefined
                  ) {
                    prevVideoPath = [];
                  }

                  const updatedVideoPath = [...prevVideoPath];

                  // If the moduleIndex does not exist, initialize it as an array
                  if (!updatedVideoPath[moduleIndex]) {
                    updatedVideoPath[moduleIndex] = [];
                  }

                  updatedVideoPath[moduleIndex][partIndex] =
                    URL.createObjectURL(file);
                  return updatedVideoPath;
                });

                return {
                  ...part,
                  video: file, // Set the video property to the file
                };
              } else {
                // If the user deletes the video
                setVideoPath((prevVideoPath) => {
                  const updatedVideoPath = [...prevVideoPath];
                  updatedVideoPath[moduleIndex][partIndex] = "";
                  return updatedVideoPath;
                });

                return {
                  ...part,
                  video: null, // Set the video to null or handle it based on your data structure
                };
              }
            }
            return part;
          }),
        };
      }
      return module;
    });

    const newSelectedVideoNames = [...selectedVideoNames];
    const fileName = event.target.files[0]?.name || "";
    newSelectedVideoNames[
      moduleIndex * modules[moduleIndex].parts.length + partIndex
    ] = fileName;

    setSelectedVideoNames(newSelectedVideoNames);
    setModules(newModules);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDeleteVideo = (moduleIndex, partIndex) => {
    const newModules = modules.map((module, mIndex) => {
      if (mIndex === moduleIndex) {
        return {
          ...module,
          parts: module.parts.map((part, pIndex) => {
            if (pIndex === partIndex) {
              // Make sure setVideoPath is properly initialized
              setVideoPath((prevVideoPath) => {
                const updatedVideoPath = [...prevVideoPath];
                // Make sure moduleIndex and partIndex are within bounds
                if (
                  updatedVideoPath[moduleIndex] &&
                  updatedVideoPath[moduleIndex][partIndex]
                ) {
                  updatedVideoPath[moduleIndex][partIndex] = "";
                }
                return updatedVideoPath;
              });

              return {
                ...part,
                video: null, // Set the video to null or handle it based on your data structure
              };
            }
            return part;
          }),
        };
      }
      return module;
    });

    setModules(newModules);
  };
  const handleDeletePart = async (moduleIndex, partIndex) => {
    try {
      // Open the confirmation dialog
      const result = await ShowDialogBox({
        title: "Delete Part",
        text: "Are you sure you want to delete this part?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      // Proceed with deletion if the user clicked "Yes"
      if (result.isConfirmed) {
        setModules((prevModules) => {
          const updatedModules = [...prevModules];
          // Ensure moduleIndex is within bounds
          if (moduleIndex >= 0 && moduleIndex < updatedModules.length) {
            const module = { ...updatedModules[moduleIndex] };
            const updatedParts = module.parts.filter(
              (part, index) => index !== partIndex
            );
            module.parts = updatedParts;
            updatedModules[moduleIndex] = module;
          }
          return updatedModules;
        });
      }
    } catch (error) {
      console.error("Error deleting client:", error);

      ShowDialogBox({
        title: "Error",
        text: "An error occurred while deleting the item.",
        icon: "error",
      });
    }
  };
  const handleDeleteModule = async (moduleIndex) => {
    try {
      // Open the confirmation dialog
      const result = await ShowDialogBox({
        title: "Delete Part",
        text: "Are you sure you want to delete this part?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      // Proceed with deletion if the user clicked "Yes"
      if (result.isConfirmed) {
        setModules((prevModules) => {
          const updatedModules = [...prevModules];
          // Ensure moduleIndex is within bounds
          if (moduleIndex >= 0 && moduleIndex < updatedModules.length) {
            updatedModules.splice(moduleIndex, 1);
          }
          return updatedModules;
        });
      }
    } catch (error) {
      console.error("Error deleting client:", error);

      ShowDialogBox({
        title: "Error",
        text: "An error occurred while deleting the item.",
        icon: "error",
      });
    }
  };
  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const quillFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  const handleModuleMoveUp = (e, index) => {
    e.preventDefault();
    const newArray = [...modules];
    const temp = newArray[index];
    newArray[index] = newArray[index - 1];
    newArray[index - 1] = temp;
    setModules(newArray);
  };
  const handleModuleMoveDown = (e, index) => {
    e.preventDefault();

    const newArray = [...modules];
    const temp = newArray[index];
    newArray[index] = newArray[index + 1];
    newArray[index + 1] = temp;
    setModules(newArray);
  };

  const handlePartMoveUp = (e, moduleIndex, partIndex) => {
    e.preventDefault();
    if (partIndex <= 0) return; // Don't proceed if trying to move the first element up

    const newArray = modules.map((module, index) => {
      if (index === moduleIndex) {
        const moduleParts = [...module.parts];
        const temp = moduleParts[partIndex];
        moduleParts[partIndex] = moduleParts[partIndex - 1];
        moduleParts[partIndex - 1] = temp;
        return { ...module, parts: moduleParts };
      }
      return module;
    });

    setModules(newArray);
  };

  const handlePartMoveDown = (e, moduleIndex, partIndex) => {
    e.preventDefault();
    const lastIndex = modules[moduleIndex].parts.length - 1;
    if (partIndex >= lastIndex) return; // Don't proceed if trying to move the last element down

    const newArray = modules.map((module, index) => {
      if (index === moduleIndex) {
        const moduleParts = [...module.parts];
        const temp = moduleParts[partIndex];
        moduleParts[partIndex] = moduleParts[partIndex + 1];
        moduleParts[partIndex + 1] = temp;
        return { ...module, parts: moduleParts };
      }
      return module;
    });

    setModules(newArray);
  };

  return (
    <div className="">
      <div className="p-4 flex flex-col gap-2  ">
        {/* Breadcrumbs */}
        <div className=" text-black font-bold">
          {/* <ATMBreadCrumbs breadcrumbs={breadcrumbs} /> */}
        </div>

        {/* Page Heading */}
        <div className="pt-1 pb-2 pl-2">
          <span className="text-xl font-semibold text-black">Edit Course</span>
        </div>
        <form onSubmit={onSubmit}>
          <div className="grow max-h-full bg-white border  rounded-xl shadow  bg-form-bg bg-cover bg-no-repeat p-4">
            <div className="flex justify-between px-3 h-[60px] items-center border-b border-slate-300">
              <label className="flex cursor-pointer select-none items-center font-semibold">
                Enable/Disable
                <div className="relative ml-1">
                  <input
                    type="checkbox"
                    name="enabled"
                    value={formData?.enabled}
                    checked={formData?.enabled}
                    onChange={() => {
                      setFormData({ ...formData, enabled: !formData?.enabled });
                    }}
                    className="sr-only "
                  />
                  <div className="h-5 w-14 rounded-full bg-orange-400 border shadow-inner"></div>
                  <div
                    className={`dot  shadow-switch-1 absolute left-0 -top-1 flex h-7 w-7 items-center justify-center rounded-full transition duration-300 ${
                      formData?.enabled
                        ? "translate-x-7 bg-black"
                        : "translate-x-0 bg-black"
                    }`}
                  >
                    <span
                      className={`active h-4 w-4 rounded-full ${
                        formData?.enabled ? "bg-primary" : "bg-[#fff]"
                      }`}
                    ></span>
                  </div>
                </div>
              </label>

              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  disabled={apiStatus}
                  // onClick={onSubmit}
                  className={`bg-orange-400 rounded py-1 px-5 text-white border border-primary-main ${
                    apiStatus ? "opacity-50" : ""
                  }`}
                >
                  Update Course
                </button>
              </div>
            </div>
            <div className="grow py-8 px-3 ">
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="ml-1 font-medium mb-2 ">
                    Select Category
                  </label>

                  <select
                    name="categoryId"
                    value={formData.categoryId}
                    onChange={handleInputChange}
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                  >
                    {data?.map((category) => (
                      <option
                        key={category._id} // Assuming your category object has an _id property
                        className="text-black"
                        value={category._id} // Send category ID as the value
                      >
                        {category.title}{" "}
                        {/* Assuming your category object has a name property */}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="title" className="ml-1 font-medium mb-2  ">
                    Title{" "}
                  </label>
                  <input
                    type="type"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="Enter Course Title"
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 "
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="event_name" className="ml-1 ">
                    Sub Title{" "}
                  </label>
                  <input
                    type="text"
                    name="subTitle"
                    placeholder="Sub title"
                    value={formData.subTitle}
                    onChange={handleInputChange}
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 "
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="price" className="ml-1 font-medium mb-2  ">
                    Price
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={formData?.price}
                    placeholder="price"
                    courseValidityDayscourseValidityDays
                    onChange={handleInputChange}
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 "
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="rating" className="ml-1 font-medium mb-2 ">
                    Rating
                  </label>
                  <input
                    type="number"
                    name="rating"
                    id="rating"
                    value={formData.rating}
                    onChange={handleInputChange}
                    placeholder="Rating"
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 "
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="overview" className="ml-1 font-medium mb-2 ">
                    Overview
                  </label>
                  <input
                    type="text"
                    name="overview"
                    id="overview"
                    placeholder="Overview"
                    value={formData.overview}
                    onChange={handleInputChange}
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col mt-2">
                  <label
                    htmlFor="courseValidityDays"
                    className="ml-1 font-medium mb-2  "
                  >
                    Course Validity Days
                  </label>
                  <input
                    name="courseValidityDays"
                    type="number"
                    value={formData?.courseValidityDays}
                    onChange={handleInputChange}
                    id="courseValidityDays"
                    placeholder="No of days"
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                  />
                </div>
                <div className="flex flex-col mt-2">
                  <label
                    htmlFor="courseDuration"
                    className="ml-1 font-medium mb-2  "
                  >
                    Course Duration
                  </label>
                  <input
                    name="courseDuration"
                    type="number"
                    value={formData?.courseDuration}
                    onChange={handleInputChange}
                    id="courseDuration"
                    placeholder="Enter in minutes"
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div className="flex flex-col mt-2">
                  <label
                    htmlFor="coverImage"
                    className="ml-1 font-medium mb-2  "
                  >
                    Cover Image
                  </label>
                  <input
                    type="file"
                    name={`coverImage`}
                    accept="image/*"
                    className="bg-transparent w-full border border-gray-700 rounded-lg py-2 px-4"
                  />
                </div>
                <div className="flex flex-col mt-2">
                  <label
                    htmlFor="coverImageURL"
                    className="ml-1 font-medium mb-2  "
                  >
                    Cover Image Path
                  </label>
                  <input
                    type="text"
                    disabled
                    value={formData?.coverImage ? formData.coverImage : ""}
                    className="bg-transparent w-full border border-gray-700 rounded-lg py-2 px-4"
                  />
                </div>
                <div className="flex flex-col mt-2">
                  <label
                    htmlFor="coverVideo"
                    className="ml-1 font-medium mb-2  "
                  >
                    Cover Video
                  </label>
                  <input
                    type="file"
                    name={`coverVideo`}
                    accept="video/*"
                    fileName={formData.coverVideo}
                    className="bg-transparent w-full border border-gray-700 rounded-lg py-2 px-4"
                    // required
                  />
                </div>
                <div className="flex flex-col mt-2">
                  <label
                    htmlFor="coverImageURL"
                    className="ml-1 font-medium mb-2  "
                  >
                    Cover Video Path
                  </label>
                  <input
                    type="text"
                    disabled
                    value={formData?.coverVideo ? formData.coverVideo : ""}
                    className="bg-transparent w-full border border-gray-700 rounded-lg py-2 px-4"
                  />
                </div>
              </div>

              <div className="flex w-full flex-col mt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="description"
                    className="ml-1 font-medium mb-2 "
                  >
                    Description{" "}
                  </label>
                  {/* <textarea
                    name="description"
                    id="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    rows={10}
                    placeholder="Description"
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                  /> */}
                  <ReactQuill
                    id="description"
                    value={
                      richTextDescription
                        ? richTextDescription
                        : formData.description
                    }
                    onChange={(e) => setRichTextDescription(e)}
                    name="description"
                    modules={quillModules}
                    formats={quillFormats}
                    className="bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                    required
                  />
                </div>
              </div>
              <div className="flex w-full flex-col mt-4">
                <label htmlFor="modules" className="ml-1 font-medium  ">
                  Modules
                </label>
                {modules.map((module, moduleIndex) => (
                  <div
                    key={moduleIndex}
                    className="flex mt-4 w-full flex-col bg-slate-200 rounded-xl p-4"
                  >
                    <div className="flex justify-between items-center mb-4">
                      <div className="flex px-3 shadow-xl py-1 mb-2 bg-black text-white rounded-full w-fit">
                        {moduleIndex + 1}
                      </div>
                      <div className="flex ">
                        <button
                          disabled={moduleIndex === 0}
                          onClick={(e) => handleModuleMoveUp(e, moduleIndex)}
                          className={`${
                            moduleIndex === 0 ? "cursor-not-allowed" : ""
                          } text-4xl`}
                        >
                          <IoArrowUpCircle />
                        </button>
                        <button
                          disabled={moduleIndex === modules.length - 1}
                          onClick={(e) => handleModuleMoveDown(e, moduleIndex)}
                          className={`text-4xl`}
                        >
                          <IoArrowDownCircleSharp />
                        </button>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <input
                        type="text"
                        name={`module-${moduleIndex}-name`}
                        placeholder="Module Name"
                        value={module.name}
                        onChange={(e) =>
                          handleModuleChange(
                            moduleIndex,
                            "name",
                            e.target.value
                          )
                        }
                        className="w-full bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                      />
                      <button
                        type="button"
                        onClick={() => handleDeleteModule(moduleIndex)}
                        className="min-w-fit bg-orange-400 text-white text-sm rounded font-medium mx-1 px-4 py-2"
                      >
                        Delete Module
                      </button>
                    </div>
                    <div className="flex w-full flex-col mt-4">
                      <label
                        htmlFor="summary"
                        className="ml-1 font-medium mb-2 "
                      >
                        Summary
                      </label>
                      <textarea
                        name={`module-${moduleIndex}-summary`}
                        rows={5}
                        placeholder="Enter Course Summary"
                        value={module.summary} // Assuming summary is in the first module
                        onChange={(e) =>
                          handleModuleChange(
                            moduleIndex,
                            "summary",
                            e.target.value
                          )
                        }
                        className="bg-transparent border border-gray-700 rounded-lg py-3 px-4"
                      />
                    </div>
                    <div className="mt-4 w-full">
                      <label
                        htmlFor={`module-${moduleIndex}-parts`}
                        className="ml-1 font-medium mb-2 "
                      >
                        Parts
                      </label>
                      {module.parts.map((part, partIndex) => (
                        <div
                          key={partIndex}
                          className="flex items-center w-full gap-3 mt-2"
                        >
                          <div className="w-full">
                            <input
                              type="text"
                              name={`module-${moduleIndex}-part-${partIndex}-partNumber`}
                              placeholder="Part Number"
                              value={part.partNumber}
                              onChange={(e) =>
                                handlePartChange(
                                  moduleIndex,
                                  partIndex,
                                  "partNumber",
                                  e.target.value
                                )
                              }
                              className="bg-transparent w-full border border-gray-700 rounded-lg py-3 px-4"
                            />
                          </div>
                          <div className="w-full">
                            {/* Display video name here */}
                            <input
                              type="text"
                              placeholder="Video Name"
                              // value={
                              //   videoPath?.[moduleIndex]?.[partIndex] || ""
                              // }

                              value={
                                part?.video?.name
                                  ? part?.video?.name
                                  : part?.video
                              }
                              readOnly
                              className="bg-transparent w-full border border-gray-700 rounded-lg py-3 px-4"
                            />
                          </div>
                          {/* <button
                            type="button"
                            onClick={() =>
                              handleDeleteVideo(moduleIndex, partIndex)
                            }
                            className="bg-red-500 text-white rounded-md px-2 py-1 ml-2"
                          >
                            Clear
                          </button> */}
                          <div className="w-full">
                            <input
                              type="file"
                              name={`module-${moduleIndex}-part-${partIndex}-video`}
                              onChange={(e) =>
                                handleVideoChange(moduleIndex, partIndex, e)
                              }
                              className="bg-transparent w-full border border-gray-700 rounded-lg py-2 px-4"
                            />
                          </div>
                          <div className="flex ">
                            <button
                              disabled={partIndex === 0}
                              onClick={(e) =>
                                handlePartMoveUp(e, moduleIndex, partIndex)
                              }
                              className={`${
                                partIndex === 0 ? "cursor-not-allowed" : ""
                              } text-4xl`}
                            >
                              <IoArrowUpCircle />
                            </button>
                            <button
                              disabled={
                                partIndex ===
                                modules[moduleIndex].parts.length - 1
                              }
                              onClick={(e) =>
                                handlePartMoveDown(e, moduleIndex, partIndex)
                              }
                              className={`text-4xl`}
                            >
                              <IoArrowDownCircleSharp />
                            </button>
                          </div>
                          <div
                            onClick={() =>
                              handleDeletePart(moduleIndex, partIndex)
                            }
                            className="border p-1 rounded-full shadow-xl bg-red-700"
                          >
                            <MdDelete className="text-white text-2xl" />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        onClick={() => handleAddPart(moduleIndex)}
                        className=" bg-orange-400 text-white text-sm rounded font-medium px-4 py-2"
                      >
                        Add Part
                      </button>
                    </div>
                  </div>
                ))}
                <div>
                  <button
                    type="button"
                    onClick={() => handleAddModule()}
                    className="bg-orange-400 text-white font-medium text-sm rounded px-4 py-2 mt-2"
                  >
                    Add New Module
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCourseListing;
