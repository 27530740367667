import apiSlice from "./apiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //***** GET *****/
    getUsers: builder.query({
      providesTags: ["user"],
      query: (page) => ({
        url: `api/users/${page}`,
        method: "GET",
      }),
    }),

    //***** LOGIN *****/
    login: builder.mutation({
      invalidatesTags: ["admin"],
      query: (body) => ({
        url: "api/admin/login",
        method: "POST",
        body,
      }),
    }),

    //***** LOG OUT *****/

    logout: builder.mutation({
      invalidatesTags: ["user"],
      query: () => ({
        url: "api/user/logout",
        method: "GET",
      }),
    }),

    //***** LOG OUT FROM ALL DEVICES *****/
    logoutFromAll: builder.mutation({
      invalidatesTags: ["user"],
      query: (body) => ({
        url: "/user/logout",
        method: "POST",
        body,
      }),
    }),

    //***** ADD *****/
    addUser: builder.mutation({
      invalidatesTags: ["user"],
      query: (body) => ({
        url: "api/register",
        method: "POST",
        body,
      }),
    }),

    // **** GET BY ID
    getUserById: builder.query({
      providesTags: ["user"],
      query: (id) => ({
        url: `api/user/${id}`,
        method: "GET",
      }),
    }),

    //***** ADD New User*****/
    addNewUser: builder.mutation({
      invalidatesTags: ["newUser"],
      query: (body) => ({
        url: "api/user/register",
        method: "POST",
        body,
      }),
    }),
    updateUsers: builder.mutation({
      invalidatesTags: ["user"],
      query: ({ body, id }) => ({
        url: `api/user/${id}`,

        method: "PUT",
        body,
      }),
    }),

    // **** GET BY ID

    deleteUsers: builder.mutation({
      invalidatesTags: ["user"],
      query: (id) => ({
        url: `api/user/${id}`,

        method: "DELETE",
      }),
    }),
    // *** Admin
    getAdmins: builder.query({
      providesTags: ["admins"],
      query: (page) => ({
        url: `api/admin/all/${page}`,
        method: "GET",
      }),
    }),
    deleteAdmins: builder.mutation({
      invalidatesTags: ["admin"],
      query: (id) => ({
        url: `api/admin/${id}`,

        method: "DELETE",
      }),
    }),
    updateAdminProfile: builder.mutation({
      invalidatesTags: ["admin"],
      query: (body) => ({
        url: `api/admin/updateProfile`,
        method: "PUT",
        body,
      }),
    }),
    updateAdminPassword: builder.mutation({
      invalidatesTags: ["admin"],
      query: (body) => ({
        url: `api/admin/updatePassword`,
        method: "PUT",
        body,
      }),
    }),

    deleteMultpleUsers: builder.mutation({
      invalidatesTags: ["auth", "user"],
      query: (body) => ({
        url: `api/user/selected`,
        method: "POST",
        body,
      }),
    }),

    deleteMultpleAdmins: builder.mutation({
      invalidatesTags: ["auth", "user"],
      query: (body) => ({
        url: `api/admin/selected`,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useGetUsersQuery,
  useAddUserMutation,
  useGetUserByIdQuery,
  useLoginMutation,
  useLogoutMutation,
  useLogoutFromAllMutation,
  useRefreshTokenMutation,
  useGetNewUsersQuery,
  useAddNewUserMutation,
  useUpdateUsersMutation,
  useDeleteUsersMutation,
  useGetAdminsQuery,
  useDeleteAdminsMutation,
  useUpdateAdminProfileMutation,
  useUpdateAdminPasswordMutation,
  useDeleteMultpleAdminsMutation,
  useDeleteMultpleUsersMutation,
} = userApi;
