import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../../../utils/constants";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import ShowDialogBox from "../../../components/DialogBox/ShowDialogBox";
import { IoMdArrowRoundBack } from "react-icons/io";

const AddQuizListing = ({ onAddQuestion }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const courseId = params.id;
  const [questionText, setQuestion] = useState("");
  const [correctOption, setCorrectOption] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [apiStatus, setApiStatus] = useState(false);
  const [existingQuizs, setExistingQuizs] = useState([]);

  useEffect(() => {
    const fetchAllQuestionOfCourse = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/finalQuiz/${courseId}`
        );
        if (response?.data?.success) {
          setExistingQuizs(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (courseId) {
      fetchAllQuestionOfCourse();
    }
  }, [courseId]);

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };
  const handleCorrectOptionChange = (e) => {
    setCorrectOption(e.target.value);
  };
  const handleAddQuestion = async (e) => {
    e.preventDefault();

    try {
      setApiStatus(true);
      const formData = {
        courseId: courseId,
        questionText: e.target.questionText.value,
        options: options,
        correctOption: correctOption,
      };
      const response = await axios.post(
        `${BASE_URL}/api/finalQuiz/add`,
        formData
      );

      if (response.data.success) {
        toast.success("Quiz added successfully");
        setExistingQuizs((prev) => [...prev, response.data.data]);
        setQuestion("");
        setOptions(["", "", "", ""]);
        setCorrectOption("");
      } else {
        toast.error("Error adding Quiz");
      }
    } catch (error) {
      console.error("Error adding Quiz:", error);
      toast.error("Error adding Quiz");
    } finally {
      setApiStatus(false);
    }
  };
  const deleteQuestion = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/finalQuiz/${id}`);
      if (response.data.success) {
        setExistingQuizs((prev) =>
          prev.filter((question) => question._id !== id)
        );
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDelete = async (id) => {
    try {
      // Open the confirmation dialog
      const result = await ShowDialogBox({
        title: "Delete Question",
        text: "Are you sure you want to delete this question?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      // Proceed with deletion if the user clicked "Yes"
      if (result.isConfirmed) {
        deleteQuestion(id);
      }
    } catch (error) {
      console.error("Error deleting client:", error);

      ShowDialogBox({
        title: "Error",
        text: "An error occurred while deleting the item.",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <div className="p-4 flex flex-col gap-2  ">
        <div className="pt-1 flex items-center ">
          <button
            onClick={() => navigate(`/quiz/${courseId}`)}
            className="border-1 p-2 rounded-full mx-2 cursor-pointer border-black text-2xl"
          >
            <IoMdArrowRoundBack />
          </button>
          <span className="text-xl font-semibold text-slate-600">
            Add Final Quiz Question
          </span>
        </div>

        <form onSubmit={handleAddQuestion}>
          <div className="grow max-h-full bg-white border bg-1 rounded shadow  bg-form-bg bg-cover bg-no-repeat">
            <div className="flex justify-between px-3 h-[60px] items-center border-b border-slate-300">
              {/* Form Heading */}
              <div className="text-xl font-medium"> Question Details</div>

              <div>
                <button
                  type="submit"
                  disabled={apiStatus}
                  className={`bg-blue-700 rounded py-1 px-5 text-white border border-primary-main ${
                    apiStatus ? "opacity-50" : ""
                  }`}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="grow py-8 px-3">
              <div className="flex flex-col">
                <label>Question:</label>
                <input
                  type="text"
                  name="questionText"
                  value={questionText}
                  onChange={(e) => setQuestion(e.target.value)}
                  className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 col-span-12"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label>
                  Options:
                  <div className="flex flex-col gap-2">
                    {options.map((option, index) => (
                      <input
                        key={index}
                        type="text"
                        value={option}
                        onChange={(e) =>
                          handleOptionChange(index, e.target.value)
                        }
                        className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 col-span-6"
                        required
                      />
                    ))}
                  </div>
                </label>
              </div>
              <div className="flex flex-col">
                <label>Correct option</label>
                {/* Radio button to select correct option */}
                <input
                  type="text"
                  name="correctOption"
                  value={correctOption}
                  onChange={handleCorrectOptionChange}
                  className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 col-span-12"
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Existing Questions */}
      <div className="p-4 bg-white">
        <h1> Existing Questions </h1>
        <div className="p-2 bg-gray-300 border  ">
          {existingQuizs?.length === 0 && <div>No Question Found</div>}
          {existingQuizs?.length !== 0 &&
            existingQuizs?.map((question, index) => (
              <>
                <div className="flex text-md m-2 justify-between">
                  <h1 key={index}>{`Question ${index + 1}: ${
                    question.questionText
                  }`}</h1>
                  <div className="flex items-center">
                    <Link
                      className="text-2xl mx-1"
                      to={{
                        pathname: `/finalQuiz/${courseId}/${question._id}`,
                      }}
                    >
                      <CiEdit />
                    </Link>
                    <button onClick={() => handleDelete(question?._id)}>
                      <MdDelete className="text-2xl mx-1 text-red-700" />
                    </button>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AddQuizListing;
