import Sidenavlayout from "../../components/layout/sidenavLayout/Sidenavlayout";
import ChangePassword from "./ChangePassword";

const ChangePasswordWrapper = () => {
  return (
    <>
      <Sidenavlayout>
        <ChangePassword />
      </Sidenavlayout>
    </>
  );
};

export default ChangePasswordWrapper;
