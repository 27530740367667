import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  useAddCourseMutation,
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../../../redux/slices/services/courseServices";
import Sidenavlayout from "../../../components/layout/sidenavLayout/Sidenavlayout";
import {
  setCategoryData,
  setIsTableLoading,
} from "../../../redux/slices/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetCategoryQuery } from "../../../redux/slices/services/categoryService";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EditCourseListing from "./EditCourseListing";
import { BASE_URL } from "../../../utils/constants";
import {
  setCourseData,
  setSelectedCourseData,
} from "../../../redux/slices/CourseSlice";

const EditCourse = () => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(false);
  const params = useParams();
  const slug = params.slug;

  const navigate = useNavigate();
  const [updateCourse] = useUpdateCourseMutation();
  const { courseData } = useSelector((state) => state.course);
  const { data, isLoading, isFetching } = useGetCourseByIdQuery(slug);

  const [modules, setModules] = useState([
    { name: "", parts: [{ partNumber: 1, video: "" }] },
  ]);
  const [richTextDescription, setRichTextDescription] = useState("");

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setCategoryData(data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  const handleAddCourse = async (event) => {
    event.preventDefault();
    const {
      description,
      title,
      rating,
      price,
      categoryId,
      subTitle,
      overview,
      enabled,
      coverVideo,
      coverImage,
      courseValidityDays,
      courseDuration,
    } = event.target.elements;
    try {
      setApiStatus(true);

      // Create FormData object
      const formData = new FormData();
      formData.append("categoryId", categoryId.value);
      formData.append("title", title.value);
      formData.append("subTitle", subTitle.value);
      formData.append("overview", overview.value);
      formData.append("description", richTextDescription);
      formData.append("rating", rating.value);
      formData.append("price", price.value);
      formData.append("enabled", enabled.value);
      formData.append("courseValidityDays", courseValidityDays.value);
      formData.append("courseDuration", courseDuration.value);
      formData.append("coverVideo", coverVideo.files[0]);
      formData.append("coverImage", coverImage.files[0]);

      // Append modules data to FormData
      modules.forEach((module, moduleIndex) => {
        formData.append(`modules[${moduleIndex}][_id]`, module._id);
        formData.append(`modules[${moduleIndex}][name]`, module.name);

        module.parts.forEach((part, partIndex) => {
          if (part._id) {
            formData.append(
              `modules[${moduleIndex}][parts][${partIndex}][_id]`,
              part?._id
            );
          }
          formData.append(
            `modules[${moduleIndex}][parts][${partIndex}][partNumber]`,
            part.partNumber
          );
          formData.append(
            `modules[${moduleIndex}][parts][${partIndex}][video]`,
            part.video
          );
        });
      });
      const response = await axios.put(
        `${BASE_URL}/api/course/${data?.data?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response && response.data && response.data.updatedCourse) {
        const updatedCourse = response?.data?.updatedCourse;
        const courseIdToUpdate = updatedCourse?._id;

        const indexToUpdate = courseData.findIndex(
          (course) => course._id === courseIdToUpdate
        );

        const updatedCourseData = [...courseData];
        updatedCourseData[indexToUpdate] = updatedCourse;

        // Dispatch actions and wait for them to complete
        Promise.all([
          dispatch(setSelectedCourseData(updatedCourse)),
          dispatch(setCourseData(updatedCourseData)),
        ])
          .then((res) => {
            // Both dispatch actions completed successfully
            toast.success("Course Updated");
            navigate(`/quiz/${data?.data?._id}`);
            setApiStatus(false);
          })
          .catch((error) => {
            // Handle errors if dispatch actions fail
            toast.error("Error updating course");
            setApiStatus(false);
          });
      } else {
        setApiStatus(false);
        toast.error("Error adding course");
      }
    } catch (error) {
      console.error("Error adding course:", error);
      toast.error("Error adding course");
      setApiStatus(false);
    }
  };

  const handleModulesChange = (updatedModules) => {
    setModules(updatedModules);
  };

  return (
    <Sidenavlayout>
      <EditCourseListing
        onSubmit={handleAddCourse}
        onModulesChange={handleModulesChange}
        apiStatus={apiStatus}
        richTextDescription={richTextDescription}
        setRichTextDescription={setRichTextDescription}
      />
    </Sidenavlayout>
  );
};

export default EditCourse;
