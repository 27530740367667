export const DateFormatter = (date) => {
  // Array of month names in alphabetical order
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, and year from the date
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Format the date as day-month-year with month name in alphabetic order
  const formattedDate = `${day}-${monthNames[monthIndex]}-${year}`;

  return formattedDate;
};
