import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableHeader from "../../../components/Table/TableHeader";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import ShowDialogBox from "../../../components/DialogBox/ShowDialogBox";
import { useDeleteMultpleOrdersMutation } from "../../../redux/slices/services/orderService";

const OrderList = ({
  rows,
  columns,
  isLoading,
  currentPage,
  selectedRows,
  setSelectedRows,
}) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteMutipleOrder] = useDeleteMultpleOrdersMutation();

  // Extract values from each row object
  const rowValues = rows?.map((row) =>
    Object.values(row).join(" ").toLowerCase()
  );

  // Filter row values based on search query
  const filteredRowValues = rowValues?.filter((value) =>
    value.includes(searchQuery.toLowerCase())
  );

  // Map filtered row values back to their original row objects
  const filteredRows = filteredRowValues?.map((filteredValue) =>
    rows.find(
      (row) => Object.values(row).join(" ").toLowerCase() === filteredValue
    )
  );

  const handleRowSelect = (selectedRows) => {
    setSelectedRows(selectedRows);
  };
  const handleDeleteMultiple = async () => {
    const result = await ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete selected items?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result?.isConfirmed) {
      const response = await deleteMutipleOrder(selectedRows);
      if (response.data.success) {
        setSelectedRows([]);
      }
    }
  };
  return (
    <div className="px-4 h-fit pt-3">
      {/* Page Header */}
      <div className="flex justify-between items-center h-[55px]">
        <span className="text-xl font-semibold text-black">Orders</span>
      </div>
      <div className="flex items-center justify-between border  mb-2">
        <input
          type="text"
          placeholder="search..."
          className="px-4 py-1  border rounded-full outline-none "
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {selectedRows?.length > 0 && (
          <button
            onClick={() => handleDeleteMultiple()}
            className="bg-red-500 px-3 text-white rounded-full"
          >
            Delete ({selectedRows?.length})
          </button>
        )}
      </div>
      <div className="border flex flex-col h-fit rounded-xl bg-white p-4 shadow">
        {/* <TableHeader page={1} rowCount={1} rowsPerPage={4} rows={rows}  /> */}

        <div className="grow overflow-auto">
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              rows={filteredRows}
              columns={columns}
              isCheckbox={true}
              currentPage={currentPage}
              onRowSelect={handleRowSelect}
              selectedRows={selectedRows}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderList;
