import React, { useEffect, useLayoutEffect, useState } from "react";
import Sidenavlayout from "../../../components/layout/sidenavLayout/Sidenavlayout";
import {
  useDeleteAdminsMutation,
  useGetAdminsQuery,
} from "../../../redux/slices/services/authService";
import {
  setAdminData,
  setIsTableLoading,
} from "../../../redux/slices/AuthSlice";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ShowDialogBox from "../../../components/DialogBox/ShowDialogBox";
import { DateFormatter } from "../../../utils/dateFormatter";
import AdminListing from "./AdminListing";
import Pagination from "../../../components/Pagination/Pagination";

const AdminListingWrapper = () => {
  localStorage.setItem("pageNumber", 1);

  const dispatch = useDispatch();
  const [deleteAdmins] = useDeleteAdminsMutation();
  const { adminData } = useSelector((state) => state.auth);
  const [page, setPage] = useState(() => {
    const storedPage = localStorage.getItem("pageNumber");
    return storedPage ? parseInt(storedPage) : 1;
  });
  const { data, isFetching, isLoading } = useGetAdminsQuery(page);
  const admins = data?.admins;
  const totalPages = data?.totalPages;

  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setAdminData(admins));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, admins, isFetching, isLoading]);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {row.name} </span>,
    },
    {
      field: "Email",
      headerName: "Email",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span> {row.email} </span>;
      },
    },

    {
      field: "date",
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => (
        <span> {DateFormatter(new Date(row.updatedAt))} </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <>
          {/* <FaPencil
            onClick={() => handleEdit(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          /> */}
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
          {/* <FaEye onClick={() => handleView(row)} style={{ cursor: 'pointer' }} /> */}
        </>

        //     // handleOnAction={() => {
        //     //     setShowDropdown(!showDropdown)
        //     //     //   setCurrentId(row?._id);
        //     // }}
        // >
        //     <></>
      ),
      align: "end",
    },
  ];

  const handleDelete = async (row) => {
    try {
      // Open the confirmation dialog
      const result = await ShowDialogBox({
        title: "Delete Item",
        text: "Are you sure you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      // Proceed with deletion if the user clicked "Yes"
      if (result.isConfirmed) {
        console.log(row._id);
        const res = await deleteAdmins(row._id);

        if (res.data) {
          dispatch(
            setAdminData(adminData.filter((admin) => admin._id !== row._id))
          );

          // ShowDialogBox({
          //   title: "Deleted!",
          //   text: "The item has been deleted successfully.",
          //   icon: "success",
          // });
        }
      }
    } catch (error) {
      console.error("Error deleting client:", error);

      ShowDialogBox({
        title: "Error",
        text: "An error occurred while deleting the item.",
        icon: "error",
      });
    }
  };

  return (
    <Sidenavlayout>
      <AdminListing
        rows={adminData}
        columns={columns}
        isLoading={isLoading}
        currentPage={page}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
      />
    </Sidenavlayout>
  );
};

export default AdminListingWrapper;
