import React from "react";
import Sidenavlayout from "../layout/sidenavLayout/Sidenavlayout";
import Invoice from "./Invoice";

const InvoiceWrapper = () => {
  return (
    <div>
      <Sidenavlayout>
        <Invoice />
      </Sidenavlayout>
    </div>
  );
};

export default InvoiceWrapper;
