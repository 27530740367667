// AddQuizWrapper.js

import React from "react";
import Sidenavlayout from "../../../components/layout/sidenavLayout/Sidenavlayout";
import EditQuiz from "./EditQuiz";

const EditQuizWrapper = () => {
  return (
    <Sidenavlayout>
      <EditQuiz />
    </Sidenavlayout>
  );
};

export default EditQuizWrapper;
