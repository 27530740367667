import React, { useEffect, useState } from "react";
import Sidenavlayout from "../../../components/layout/sidenavLayout/Sidenavlayout";
import { FaTrashAlt } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import ShowDialogBox from "../../../components/DialogBox/ShowDialogBox";
import {
  useDeleteOrderMutation,
  useGetOrderQuery,
} from "../../../redux/slices/services/orderService";
import {
  setIsTableLoading,
  setOrderData,
} from "../../../redux/slices/orderSlice";
import OrderList from "./OrderList";
import Pagination from "../../../components/Pagination/Pagination";

const OrderListWrapper = () => {
  localStorage.setItem("pageNumber", 1);

  const dispatch = useDispatch();
  const [page, setPage] = useState(() => {
    const storedPage = localStorage.getItem("pageNumber");
    return storedPage ? parseInt(storedPage) : 1;
  });
  const { data, isFetching, isLoading } = useGetOrderQuery(page);
  const [deleteOrder] = useDeleteOrderMutation();
  const orders = data?.data;
  const totalPages = data?.totalPages;
  const [selectedRows, setSelectedRows] = useState([]);

  const courseDetailsArray = [];

  data?.data.forEach((course) => {
    const courseId = course.courseId[0];
    const details = {
      _id: course?._id,
      title: courseId?.title,
      description: courseId?.description,
      totalUsers: courseId?.totalUsers,
      rating: courseId?.rating,
      price: courseId?.price,
      paymentStatus: course?.paymentStatus,
      name: course.name,
      // Add more properties as needed
    };

    // Push the details into the array
    courseDetailsArray.push(details);
  });
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setOrderData(data?.data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  const columns = [
    {
      field: "Title",
      headerName: "Title",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {row.title} </span>,
    },
    {
      field: "Price",
      headerName: "Price",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span> {row.price} </span>;
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span> {row.paymentStatus} </span>;
      },
    },
    {
      field: "Users",
      headerName: "Users",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {row.name} </span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <FaTrashAlt
          onClick={() => handleDelete(row)}
          style={{ cursor: "pointer", marginRight: "8px" }}
        />
      ),
      align: "end",
    },
  ];
  const handleDelete = async (row) => {
    console.log(row);
    try {
      const result = await ShowDialogBox({
        title: "Delete Item",
        text: "Are you sure you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      // Proceed with deletion if the user clicked "Yes"
      if (result.isConfirmed) {
        const res = await deleteOrder(row._id);

        if (res.data) {
        }
      }
    } catch (error) {
      console.error("Error deleting client:", error);

      ShowDialogBox({
        title: "Error",
        text: "An error occurred while deleting the item.",
        icon: "error",
      });
    }
  };
  return (
    <Sidenavlayout>
      <OrderList
        rows={courseDetailsArray}
        columns={columns}
        isLoading={isLoading}
        currentPage={page}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
      />
    </Sidenavlayout>
  );
};

export default OrderListWrapper;
