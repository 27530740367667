import { Slice, createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderData: [],
    selectedCourseData: null,
    isTableLoading: false,
    receipts: [],
  },
  reducers: {
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setSelectedCourseData: (state, action) => {
      state.selectedCourseData = action.payload;
    },
    setIsTableLoading: (state, action) => {
      state.isTableLoading = action.payload;
    },
    setReceipts: (state, action) => {
      state.receipts = action.payload;
    },
  },
});
export const {
  setOrderData,
  setSelectedCourseData,
  setIsTableLoading,
  setReceipts,
} = orderSlice.actions;

export default orderSlice.reducer;
