import apiSlice from "./apiSlice";

export const orderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //***** GET *****/
    getOrder: builder.query({
      providesTags: ["order"],
      query: (page) => ({
        url: `api/admin/orders/${page}`,
        method: "GET",
      }),
    }),

    //***** LOGIN *****/
    addOrder: builder.mutation({
      invalidatesTags: ["order"],
      query: (body) => ({
        url: "api/Order",
        method: "POST",
        body,
      }),
    }),
    deleteOrder: builder.mutation({
      invalidatesTags: ["order"],
      query: (id) => ({
        url: `api/order/${id}`,
        method: "DELETE",
      }),
    }),

    updateOrder: builder.mutation({
      invalidatesTags: ["order"],
      query: ({ body, id }) => ({
        url: `api/Order/${id}`,

        method: "PUT",
        body,
      }),
    }),

    // **** GET BY ID
    getOrderById: builder.query({
      providesTags: ["order"],
      query: (id) => ({
        url: `api/user/${id}`,
        method: "GET",
      }),
    }),
    getAllReceipts: builder.query({
      providesTags: ["receipts"],
      query: (id) => ({
        url: `api/transactions/all/${id}`,
        method: "GET",
      }),
    }),

    deleteMultpleOrders: builder.mutation({
      invalidatesTags: ["order"],
      query: (body) => ({
        url: `api/orders/selected`,
        method: "POST",
        body,
      }),
    }),
    deleteMultpleReceipts: builder.mutation({
      invalidatesTags: ["receipts"],
      query: (body) => ({
        url: `api/transactions/selected`,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useGetOrderQuery,
  useAddOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useGetOrderByIdQuery,
  useGetAllReceiptsQuery,
  useDeleteMultpleOrdersMutation,
  useDeleteMultpleReceiptsMutation,
} = orderApi;
