import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { DateFormatter } from "../../utils/dateFormatter";
import { useUpdateAdminProfileMutation } from "../../redux/slices/services/authService";
import { setUser } from "../../redux/slices/AuthSlice";
import { Link } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    _id: user._id,
    name: user.name,
    email: user.email,
    updatedAt: user.updatedAt,
  });
  const [updateAdminProfile, { isLoading }] = useUpdateAdminProfileMutation();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setFormData({
      _id: user._id,
      name: user.name,
      email: user.email,
      updatedAt: user.updatedAt,
    });
  };

  const handleSaveClick = async () => {
    // Handle save logic here
    const body = formData;
    const response = await updateAdminProfile(body);
    if (response) {
      dispatch(setUser(response.data));
    }
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {!user ? (
        <div className="h-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
          <div className="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-8">
              <h2 className="text-center font-bold text-3xl text-gray-800">
                Profile
              </h2>
              {isEditing ? (
                <div className="mt-8">
                  <div className="mb-4">
                    <label
                      className="block text-sm font-semibold text-gray-600"
                      htmlFor="name"
                    >
                      Name:
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-semibold text-gray-600"
                      htmlFor="email"
                    >
                      Email:
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex justify-between items-center mt-8">
                    <button
                      className="bg-orange-400 hover:bg-black hover:text-white text-black font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={handleSaveClick}
                    >
                      {isLoading ? "Saving..." : "Save Changes"}
                    </button>
                    <button
                      className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-8">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-semibold text-gray-600">
                      Name:
                    </span>
                    <span className="text-lg font-semibold text-gray-900">
                      {user.name}
                    </span>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <span className="text-sm font-semibold text-gray-600">
                      Email:
                    </span>
                    <span className="text-lg font-semibold text-gray-900">
                      {user.email}
                    </span>
                  </div>

                  <div className="flex justify-between items-center mt-4">
                    <span className="text-sm font-semibold text-gray-600">
                      Last Update:
                    </span>
                    <span className="text-lg font-semibold text-gray-900">
                      {DateFormatter(new Date(user?.updatedAt))}
                    </span>
                  </div>
                  <div className="mt-8 flex  justify-between">
                    <button
                      className="bg-orange-400 hover:bg-black hover:text-white text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={handleEditClick}
                    >
                      Edit Profile
                    </button>
                    <Link
                      to="/admin/changepassword"
                      className="bg-orange-400 hover:bg-black hover:text-white text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Change Password
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
