import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useUpdateAdminPasswordMutation } from "../../redux/slices/services/authService";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [updatePassword, { isLoading }] = useUpdateAdminPasswordMutation();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { oldPassword, newPassword, confirmPassword } = formData;
      if (!oldPassword || !newPassword || !confirmPassword) {
        return setError("All Fields required");
      }
      if (formData.newPassword !== formData.confirmPassword) {
        setError("New password and confirm password do not match.");
      } else {
        setError("");
        const body = { email: user.email, ...formData };
        const response = await updatePassword(body);
        if (response?.data && response?.data?.success) {
          toast.success(response.data.message);
          setFormData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          navigate("/profile");
        } else {
          toast.error(response.error.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went Wrong");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-8">
          <h2 className="text-center font-bold text-3xl text-gray-800">
            Change Password
          </h2>
          <form onSubmit={handleSubmit} className="mt-8">
            <div className="mb-4">
              <label
                className="block text-sm font-semibold text-gray-600"
                htmlFor="oldPassword"
              >
                Old Password:
              </label>
              <input
                type="password"
                name="oldPassword"
                id="oldPassword"
                className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                value={formData.oldPassword}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold text-gray-600"
                htmlFor="newPassword"
              >
                New Password:
              </label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                value={formData.newPassword}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold text-gray-600"
                htmlFor="confirmPassword"
              >
                Confirm Password:
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </div>
            {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-orange-400 hover:bg-black hover:text-white text-black font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isLoading ? "Processing..." : "Change Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
