import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/constants";
import toast from "react-hot-toast";
import { IoMdArrowRoundBack } from "react-icons/io";

const EditFinalQuiz = () => {
  const { questionId, courseId } = useParams();
  const [apiStatus, setApiStatus] = useState(false);
  const [question, setQuestion] = useState({});
  const navigate = useNavigate();

  const handleOptionChange = (index, value) => {
    const newOptions = [...question.options];
    newOptions[index] = value;
    setQuestion((prev) => ({
      ...prev,
      options: newOptions,
    }));
  };
  const handleEditQuestion = async (e) => {
    e.preventDefault();
    setApiStatus(true);
    try {
      const response = await axios.put(
        `${BASE_URL}/api/finalQuiz/${questionId}`,
        question
      );
      if (response.data.success) {
        setApiStatus(false);
        toast.success(response.data.message);
        if (courseId) {
          navigate(`/finalQuiz/${courseId}`);
        }
      }
    } catch (error) {
      setApiStatus(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const getQuestionDetails = async () => {
      const response = await axios.get(
        `${BASE_URL}/api/finalQuiz/question/${questionId}`
      );
      if (response.data.success) {
        setQuestion(response.data.data);
      }
    };
    getQuestionDetails();
  }, [questionId]);
  return (
    <>
      <div className="p-4 flex flex-col gap-2  ">
        <div className="pt-1 flex items-center">
          <button
            onClick={() => navigate(`/finalQuiz/${courseId}`)}
            className="border-1 p-2 rounded-full mx-2 cursor-pointer border-black text-2xl"
          >
            <IoMdArrowRoundBack />
          </button>
          <span className="text-xl font-semibold text-slate-600">
            Edit Final Quiz Question
          </span>
        </div>

        <form onSubmit={handleEditQuestion}>
          <div className="grow max-h-full bg-white border bg-1 rounded shadow  bg-form-bg bg-cover bg-no-repeat">
            <div className="flex justify-between px-3 h-[60px] items-center border-b border-slate-300">
              {/* Form Heading */}
              <div className="text-xl font-medium"> Question Details</div>

              {/* BUTTON - Add Button */}
              <div>
                <button
                  type="submit"
                  disabled={apiStatus}
                  className={`bg-blue-700 rounded py-1 px-5 text-white border border-primary-main ${
                    apiStatus ? "opacity-50" : ""
                  }`}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="grow py-8 px-3">
              <div className="flex flex-col">
                <label>Question:</label>
                <input
                  type="text"
                  name="questionText"
                  value={question.questionText}
                  onChange={(e) =>
                    setQuestion((prev) => ({
                      ...prev,
                      questionText: e.target.value,
                    }))
                  }
                  className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 col-span-12"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label>
                  Options:
                  <div className="flex flex-col gap-2">
                    {question?.options?.map((option, index) => (
                      <input
                        key={index}
                        type="text"
                        value={option}
                        onChange={(e) =>
                          handleOptionChange(index, e.target.value)
                        }
                        className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 col-span-6"
                        required
                      />
                    ))}
                  </div>
                </label>
              </div>
              <div className="flex flex-col">
                <label>Correct option</label>
                {/* Radio button to select correct option */}
                <input
                  type="text"
                  name="correctOption"
                  value={question?.correctOption}
                  onChange={(e) =>
                    setQuestion((prev) => ({
                      ...prev,
                      correctOption: e.target.value,
                    }))
                  }
                  className="bg-transparent border border-gray-700 rounded-lg py-3 px-4 col-span-12"
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditFinalQuiz;
