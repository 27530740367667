import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../../utils/constants/index";
const tagTypes = ["dashboard", "user", "auth", "category", "order", "course",];

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,

    prepareHeaders: (headers, { getState, endpoint }) => {
      const authToken = getState()?.auth?.accessToken;
      if (authToken) {
        headers.set("Authorization", authToken);
      }

      return headers;
    },
  }),
  tagTypes: tagTypes,

  endpoints: (builder) => ({
    getDashboard: builder.query({
      providesTags: ["dashboard"],
      query: (page) => ({
        url: `api/dashboard`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetDashboardQuery } = apiSlice;

export default apiSlice;
